import React, {Component} from 'react'
import PortfolioItem from './PortfolioItem';
import ModalItem from './ModalItem';

import img1 from '../assets/img/portfolio/1.jpg'
import img2 from '../assets/img/portfolio/2.jpg'
import img3 from '../assets/img/portfolio/3.jpg'
import img4 from '../assets/img/portfolio/4.jpg'
import img5 from '../assets/img/portfolio/5.jpg'
import img6 from '../assets/img/portfolio/6.jpg'
import x_icon from '../assets/icon/X.png'

const portfolio = [
        {title: 'Programming', subtitle: 'Language Diversity', image: img1, modal_ref: '#portfolioModal1'},
        {title: 'Graphic Design', subtitle: 'Stunning Imagery', image: img2, modal_ref: '#portfolioModal2'},
        {title: 'Responsive Design', subtitle: 'Websites That Matter', image: img3, modal_ref: '#portfolioModal3'},
        {title: 'Marketing', subtitle: 'What Gets The Click', image: img4, modal_ref: '#portfolioModal4'},
        {title: 'Web Security', subtitle: 'Keeping Your Site Safe', image: img5, modal_ref: '#portfolioModal5'},
        {title: 'Videography', subtitle: 'Video Editing Using Premiere Pro', image: img6, modal_ref: '#portfolioModal6'},
]

const modal = [
    {title: 'Programming', 
    subtitle: 'Language Diversity', 
    description: "I am competant in many different web languages. e.g., HTML, CSS, Javascript, React, Vue.js, three.js, docker, various sql languages.", 
    image: img1,
    close_icon: x_icon,
    modal_id: 'portfolioModal1'},

    {title: 'Graphic Design', 
    subtitle: 'Stunning Imagery', 
    image: img2,
    close_icon: x_icon,
    description: "Having taken graphic design courses and through working as a web developer, I have developed an eye for good desgin princeples. I will make your website an experience that customers won't forget.",
    modal_id: 'portfolioModal2'},


    {title: 'Responsive Design', 
    subtitle: 'Websites That Matter', 
    description: "I make Websites that are honored to have my customers time. I aim to make the experience as enjoyable as possible, I believe that a Website is not just a vechile for information, but a chance to improve a life.",
    image: img3,
    close_icon: x_icon,
    modal_id: 'portfolioModal3'},


    {title: 'Marketing', 
    subtitle: 'What Gets The Click', 
    image: img4,
    close_icon: x_icon,
    description: "I've studied what attracts a user's eyes and can employ what I've learned to help with ad placement and other marketing needs.", 
    modal_id: 'portfolioModal4'},

    {title: 'Web Security', 
    subtitle: 'Keeping Your Site Safe', 
    description: "With experience in stress testing using Burpe Suite I can ensure the security of sensitive customer data.",
    image: img5,
    close_icon: x_icon, 
    modal_id: 'portfolioModal5'},

    {title: 'Videography',
    subtitle: 'Video Editing Using Premiere Pro',
    image: img6,
    close_icon: x_icon,
    description: "I've taught myself how to produce and edit videos using adobe premiere pro. Even if your company does not require videos, I am experienced in learning new, challenging software quickly.",
    modal_id: 'portfolioModal6'},
]

class Portfolio extends Component {
    render() {
        return (
            <section className="page-section bg-black" id="portfolio">
            <div className="container">
                <div className="text-center">
                    <h2 className="section-heading text-uppercase">Experience</h2>
                    <h3 className="section-subheading text-muted">Some of the things I offer.</h3>
                </div>
                <div className="row">
                    {portfolio.map((item, index) => {
                        return <PortfolioItem {...item} key = {index} />
                    })}
                </div>
                <div>
                    {modal.map((item, index) => {
                        return <ModalItem {...item} key = {index} />
                    })}
                </div>
            </div>
        </section>
        )
    }
}

export default Portfolio;