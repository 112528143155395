import React, {Component} from 'react'
import {withFormik} from 'formik'
import * as Yup from 'yup'

//Reusable Components
import Field from '../Common/Feild'

const fields = {
    sections: [
        [
            {name: 'name', elementName: 'input', type: 'text', id: 'name', placeholder: 'Your name*'},
            {name: 'email', elementName: 'input', type: 'email', id: 'email', placeholder: 'Your email*'},
            {name: 'phone_number', elementName: 'input', type: 'tel', id: 'phone_number', placeholder: 'Your phone number*'}   
        ],
        [
            {name: 'message', elementName: 'textarea', type: 'text', id: 'message', placeholder: 'Type your message*'}
        ]
    ]
}

class Contact extends Component {
    render() {

        const backgroundStyle = {
            background: "black",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
        }

        return (
            <section className="page-section" id="contact" style={backgroundStyle}>
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">Contact</h2>
                        <h3 className="section-subheading text-muted">If you'd like to contact me, input your information and I will get back to you.
                        <br/><br/> I'm using MongoDB for this specific form.
                        </h3>
                    </div>
                    <form onSubmit= {this.props.handleSubmit}>
                        <div className="row align-items-stretch mb-5">
                            {fields.sections.map((section, sectionIndex) => {
                                return (
                                    <div className = 'col-md-6' key = {sectionIndex}>
                                        {section.map((field, i) => {
                                            return <Field
                                                        {...field} 
                                                        key = {i}
                                                        value = {this.props.values[field.name]}
                                                        name = {field.name}
                                                        onChange = {this.props.handleChange}
                                                        onBlur={this.props.handleBlur}
                                                        touched = {(this.props.touched[field.name])}
                                                        errors = {this.props.errors[field.name]}
                                                    />
                                        })}
                                    </div>
                                    
                                )
                            })}
                        </div>
                        <div className="d-none" id="submitSuccessMessage">
                            <div className="text-center text-white mb-3">
                                <div className="fw-bolder">Form submission successful!</div>
                                To activate this form, sign up at
                                <br />
                            </div>
                        </div>

                        <div className="d-none" id="submitErrorMessage"><div className="text-center text-danger mb-3">Error sending message!</div></div>

                        <div className="text-center">
                            <button 
                            className="btn btn-primary btn-xl text-uppercase" 
                            type="submit">
                                Send Message
                                </button>
                        </div>
                    </form>
                </div>
            </section>
        )
    }
}


export default withFormik({
    mapPropsToValues: () => ({
        name:'',
        email:'',
        phone_number:'',
        message:'',
    }),
    validationSchema: Yup.object().shape({
        name: Yup.string().required('Please enter your name.'),
        email: Yup.string().email('Please provide a valid email address.').required('Please enter your email.'),
        phone_number: Yup.string()
        .min(10, 'Please provide your 10 digit phone number.')
        .max(15, 'Your phone number is to long.')
        .matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'Your phone number should not contain letters.')
        .required('Please provide a valid phone number.'),
        message: Yup.string().min(30, 'You need to provide at least 30 characters.').required('Please give a reason for contacting.')
    }),
    handleSubmit: (values, {setSubmitting}) => {
        console.log("VALUES ", values)
        console.log(values)
        return fetch('http://localhost:3000/clients', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(data => console.log(data))
        .then(alert("Thank you for your message."))
    }
})(Contact);