import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class PageWrapper extends Component {
    render() {
        return (
            <div>
                <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
                    <div className="container px-4 px-lg-5">
                        <Link className="navbar-brand" to="/">Home</Link>
                        <button className="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fas fa-bars"></i>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarResponsive">
                            <ul className="navbar-nav ms-auto">
                                    {this.props.title === "about" ? 
                                    this.props.linkTitles.map((title, index) => {
                                        return (
                                            <li className = "nav-item" key = {index}><a className="nav-link" href={`#${title.toLowerCase()}`}>{title}</a></li>
                                        )
                                    })
                                    :
                                    this.props.linkTitles.map((title, index) => {
                                        return (
                                            <li className = "nav-item" key = {index}><Link className="nav-link" to={`about/#${title.toLowerCase()}`}>{title}</Link></li>
                                        )
                                    })}
                            </ul>
                        </div>
                    </div>
                </nav>
                {this.props.children}
            </div>
            
            
        )
    }
    
}

export default PageWrapper;