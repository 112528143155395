import React, {Component} from 'react';

class ModalItem extends Component {
    render(){

        const backgroundStyle = {
            background: `black`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "scroll",
            backgroundSize: "cover"
        }

        return (
            <div className="portfolio-modal modal fade" id={this.props.modal_id} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="close-modal" data-bs-dismiss="modal"><img src={this.props.close_icon} alt="Close modal" width="20" height="20"/></div>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="modal-body">
                                        
                                        <h2 className="text-uppercase">{this.props.title}</h2>
                                        <p className="item-intro modal-subtext">{this.props.subtitle}</p>
                                        <img className="img-fluid d-block mx-auto" src={this.props.image} alt="..." />
                                        <p>{this.props.description}</p>
                                        <ul className="list-inline">
                                            {/* <li>
                                                <strong>Client:</strong>
                                                {this.props.client_name}
                                            </li>
                                            <li>
                                                <strong>Category:</strong>
                                                {this.props.category_name}
                                            </li> */}
                                        </ul>
                                        <button className="btn btn-primary btn-xl text-uppercase" data-bs-dismiss="modal" type="button">
                                            <i className="fas fa-times me-1"></i>
                                            Close Project
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModalItem;