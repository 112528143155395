import React, {Component} from 'react';

class SocialBtn extends Component {
    render(){
        return (
            <a className="btn-social mx-2" href={this.props.link} target={this.props.target}><i className={this.props.icon}></i></a>
        )
    }
}

export default SocialBtn;