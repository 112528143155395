import React, {Component} from 'react';
import Header from '../Common/Header';
import image from '../assets/img/bg-signup.jpg'
import contactImage from '../assets/img/demo-image-02.jpg'

//Re-usable components
import Services from '../Common/Services'
import Portfolio from '../Common/Portfolio'
import Contact from './Contact';
import PageWrapper from '../PageWrapper';
import Footer from '../Common/Footer'

class About extends Component {
    componentDidMount() {
        document.getElementById('portfolio').scrollIntoView({ behavior: 'smooth'})
    }

    render() {
        return (
            <PageWrapper
                title = "about"
                linkTitles = {["About", "Services", "Portfolio", "Contact"]}
            >
                <div>
                        <Header
                        title = "About"
                        id = "about"
                        subtitle = "Learn how I can bring your website to life"
                        buttonText = "Back to Home"
                        link="/"
                        showButton= {false}
                        image = {image}
                        
                    />

                    <Services/>      
                    <Portfolio/>
                    <Contact
                        image = {contactImage}
                    />
                    <Footer
                        
                    />

                </div>
            </PageWrapper>

        )
    }
}



export default About;