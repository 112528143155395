import React, {Component} from 'react'
import Header from '../Common/Header'
import image from '../assets/img/bg-masthead.jpg'

import PageWrapper from '../PageWrapper';


class Home extends Component {
    render() {
        return (
            <PageWrapper
                title = "home"
                linkTitles = {["About"]}
            >
                <div>
                    <Header
                        title = "Tyler"
                        title2 = "Manwaring"
                        subtitle = "Web Designer and Software Engineer"
                        buttonText = "Get started"
                        link="/about"
                        showButton= {true}
                        image = {image}
                    />


                </div>
            </PageWrapper>
        )
    }
}

export default Home;