import React, {Component} from 'react';

import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

  //Pages
  import Home from './components/Pages/Home'; 
  import About from './components/Pages/About'
  import Contact from './components/Pages/Contact';

class App extends Component {
  render() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route
              exact = {true}
              path = "/"
              element = {<Home/>}
            />

            <Route
              path = "/about"
              element = {<About/>}
            />

            <Route
              path = "/signup"
              element = {<Contact/>}
            />

          </Routes>
      </Router>
    )
  }
}

export default App;
