import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import SingleService from './SingleService'


const services = [
    {title: 'Database Systems', description: 'Everyone has data they wish they could store, I could handle the hassle for you and provide easy access to organized data sets.', icon: 'fa-database'},
    {title: 'Responsive Design', description: 'I can make clean error proof responsive design for your company site.', icon: 'fa-laptop'},
    {title: 'Web Security', description: 'With experience in cyber security I would ensure that your data is safe and secure.', icon: 'fa-lock'}
];

const backgroundStyle = {
    background: `black`,
    color: `whitesmoke`
}

class Services extends Component {
    render() {
        return (
            <section className="page-section" id="services" style = {backgroundStyle}>
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">Services</h2>
                        <h3 className="section-subheading text-muted">What I can do for you.</h3>
                    </div>
                    <div className="row text-center">
                        {services.map((service, index) => {
                            return <SingleService {...service} key = {index}/>
                        })}
                    </div>
                </div>
            </section>
        )

    }
}

export default Services;