import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Header extends Component {

    render() {  
        const backgroundStyle = {
            background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.7) 75%, #000 100%), url(${this.props.image})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover"
        }

        return (
            <section id = {this.props.id}>
                <header className="masthead"  style = {backgroundStyle}>
                    <div className="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
                        <div className="d-flex justify-content-center">
                            <div className="text-center">
                                <h1 className="mx-auto my-0 text-uppercase">{this.props.title}</h1>
                                <h1 className="mx-auto my-0 text-uppercase">{this.props.title2}</h1>
                                <h2 className="text-white-50 mx-auto mt-2 mb-5">{this.props.subtitle}</h2>
                                {this.props.showButton &&
                                    <Link className="btn btn-primary" to={this.props.link}>{this.props.buttonText}</Link>
                                }
                            </div>
                        </div>
                    </div>
                </header>
            </section>
        )
    }

}

export default Header;
//<header className="masthead" style = {{backgroundImage: `url(${this.props.image})`}}>