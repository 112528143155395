import React, {Component} from 'react';

//Reusable Components
import SocialBtn from './SocialBtn';

const btnGroup = [
    {link: 'https://github.com/tymanwaring', target: '_blank', icon: 'fab fa-github'},
    {link: '#!', target: '', icon: 'fas fa-grip-lines'},
    {link: 'https://www.linkedin.com/in/tyler-manwaring-7a74b81ba/', target: '_blank', icon: 'fab fa-linkedin-in'},
]



class Footer extends Component {
    render(){
        return (
            <footer className="footer py-4">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 text-lg-start copywrite">Copyright &copy; Tyler Manwaring's Website 2022</div>
                    <div className="col-lg-4 my-3 my-lg-0">
                    {btnGroup.map((item, index) => {
                        return <SocialBtn {...item} key = {index} />
                    })}
                    </div>
                    <div className="col-lg-4 text-lg-end">
                        <a className="link-dark text-decoration-none me-3" href="#!">Privacy Policy</a>
                        <a className="link-dark text-decoration-none" href="#!">Terms of Use</a>
                    </div>
                </div>
            </div>
            </footer>
        )
    }
}

export default Footer;